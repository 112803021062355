.group {
    height: 100px;
    position: relative;
    width: 973px;
  }
  
  .group .overlap {
    height: 100px;
    left: -3px;
    position: absolute;
    top: 0;
    width: 302px;
  }
  
  .group .overlap-group-wrapper {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 302px;
  }
  
  .group .overlap-group {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #dddddd;
    border-radius: 6px;
    height: 102px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 300px;
  }
  
  .group .text-wrapper {
    color: #000000b2;
    font-family: var(--bold-19-0-4-px-font-family);
    font-size: var(--bold-19-0-4-px-font-size);
    font-style: var(--bold-19-0-4-px-font-style);
    font-weight: var(--bold-19-0-4-px-font-weight);
    left: 109px;
    letter-spacing: var(--bold-19-0-4-px-letter-spacing);
    line-height: var(--bold-19-0-4-px-line-height);
    position: absolute;
    text-align: center;
    top: 16px;
  }
  
  .group .div {
    color: var(--grayscale-black);
    font-family: var(--bold-40-1-px-font-family);
    font-size: var(--bold-40-1-px-font-size);
    font-style: var(--bold-40-1-px-font-style);
    font-weight: var(--bold-40-1-px-font-weight);
    left: 39px;
    letter-spacing: var(--bold-40-1-px-letter-spacing);
    line-height: var(--bold-40-1-px-line-height);
    position: absolute;
    text-align: center;
    top: 45px;
    white-space: nowrap;
    width: 219px;
  }
  
  .group .marker-pin {
    height: 60px !important;
    left: 28px !important;
    position: absolute !important;
    top: 19px !important;
    width: 60px !important;
  }
  
  .group .overlap-2 {
    height: 100px;
    left: 332px;
    position: absolute;
    top: 0;
    width: 305px;
  }
  
  .group .overlap-wrapper {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 305px;
  }
  
  .group .overlap-3 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #dddddd;
    border-radius: 6px;
    height: 102px;
    left: -1px;
    position: relative;
    top: -1px;
    width: 303px;
  }
  
  .group .text-wrapper-2 {
    color: #000000b2;
    font-family: var(--bold-19-0-4-px-font-family);
    font-size: var(--bold-19-0-4-px-font-size);
    font-style: var(--bold-19-0-4-px-font-style);
    font-weight: var(--bold-19-0-4-px-font-weight);
    left: 135px;
    letter-spacing: var(--bold-19-0-4-px-letter-spacing);
    line-height: var(--bold-19-0-4-px-line-height);
    position: absolute;
    text-align: center;
    top: 17px;
  }
  
  .group .text-wrapper-3 {
    color: #27b3aa;
    font-family: var(--bold-40-1-px-font-family);
    font-size: var(--bold-40-1-px-font-size);
    font-style: var(--bold-40-1-px-font-style);
    font-weight: var(--bold-40-1-px-font-weight);
    left: 142px;
    letter-spacing: var(--bold-40-1-px-letter-spacing);
    line-height: var(--bold-40-1-px-line-height);
    position: absolute;
    text-align: center;
    top: 45px;
  }
  
  .group .marker-pin-06-2 {
    height: 60px !important;
    left: 49px !important;
    position: absolute !important;
    top: 21px !important;
    width: 60px !important;
  }
  
  .group .overlap-4 {
    height: 100px;
    left: 672px;
    position: absolute;
    top: 0;
    width: 305px;
  }
  
  .group .text-wrapper-4 {
    color: #000000b2;
    font-family: var(--bold-19-0-4-px-font-family);
    font-size: var(--bold-19-0-4-px-font-size);
    font-style: var(--bold-19-0-4-px-font-style);
    font-weight: var(--bold-19-0-4-px-font-weight);
    left: 99px;
    letter-spacing: var(--bold-19-0-4-px-letter-spacing);
    line-height: var(--bold-19-0-4-px-line-height);
    position: absolute;
    text-align: center;
    top: 19px;
  }
  
  .group .text-wrapper-5 {
    color: #c53d12;
    font-family: var(--bold-40-1-px-font-family);
    font-size: var(--bold-40-1-px-font-size);
    font-style: var(--bold-40-1-px-font-style);
    font-weight: var(--bold-40-1-px-font-weight);
    left: 127px;
    letter-spacing: var(--bold-40-1-px-letter-spacing);
    line-height: var(--bold-40-1-px-line-height);
    position: absolute;
    text-align: center;
    top: 47px;
  }
  
  .group .marker-pin-04-2 {
    height: 60px !important;
    left: 33px !important;
    position: absolute !important;
    top: 19px !important;
    width: 60px !important;
  }
  