.d-istrict {
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .d-istrict .overlap-wrapper {
    background-color: #ededed;
    height: 950px;
    width: 1310px;
  }
  
  .d-istrict .overlap {
    height: 916px;
    left: 18px;
    position: relative;
    width: 1275px;
  }
  
  .d-istrict .div {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 102px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 37px;
    width: 45px;
  }
  
  .d-istrict .group {
    height: 277px;
    left: 316px;
    position: absolute;
    top: 214px;
    width: 314px;
  }
  
  .d-istrict .overlap-group {
    background-color: #ffffff;
    border: 0.5px solid;
    border-color: #999999;
    border-radius: 15px;
    height: 277px;
    position: relative;
    width: 290px;
  }
  
  .d-istrict .overlap-group-wrapper {
    height: 45px;
    left: 122px;
    position: absolute;
    top: 24px;
    width: 47px;
  }
  
  .d-istrict .div-wrapper {
    background-color: #ff9f69;
    border-radius: 22.5px;
    height: 45px;
    position: relative;
    width: 45px;
  }
  
  .d-istrict .text-wrapper-2 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 9px;
  }
  
  .d-istrict .text-wrapper-3 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 176px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 158px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-4 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 177px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 186px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-5 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 176px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 210px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-6 {
    color: #00000080;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 48px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 158px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-7 {
    color: #00000080;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 48px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 130px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-8 {
    color: #00000080;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 48px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 186px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-9 {
    color: #00000080;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 48px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 210px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-10 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 164px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 182px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-11 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 164px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 156px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-12 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 164px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 130px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-13 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 164px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 210px;
    white-space: nowrap;
  }
  
  .d-istrict .text-wrapper-14 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 176px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 130px;
    white-space: nowrap;
  }
  
  .d-istrict .group-2 {
    height: 25px;
    left: 114px;
    position: absolute;
    top: 90px;
    width: 65px;
  }
  
  .d-istrict .text-wrapper-15 {
    color: #00a187;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .d-istrict .line {
    height: 1px;
    left: 0;
    position: absolute;
    top: 24px;
    width: 63px;
  }
  
  .d-istrict .group-3 {
    height: 277px;
    left: 942px;
    position: absolute;
    top: 214px;
    width: 314px;
  }
  
  .d-istrict .group-4 {
    height: 916px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .d-istrict .group-wrapper {
    height: 791px;
    left: 0;
    position: absolute;
    top: 125px;
    width: 1275px;
  }
  
  .d-istrict .group-5 {
    height: 791px;
    position: relative;
  }
  
  .d-istrict .group-6 {
    background-color: #ffffff;
    border-radius: 5px;
    height: 791px;
    left: 299px;
    position: absolute;
    top: 0;
    width: 976px;
  }
  
  .d-istrict .group-7 {
    height: 664px;
    left: 19px;
    position: relative;
    top: 20px;
    width: 943px;
  }
  
  .d-istrict .group-8 {
    height: 44px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 915px;
  }
  
  .d-istrict .group-9 {
    height: 44px;
    position: relative;
  }
  
  .d-istrict .group-10 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 362px;
  }
  
  .d-istrict .overlap-group-2 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #cdcdcd;
    border-radius: 50px;
    height: 44px;
    position: relative;
    width: 360px;
    margin-left: -100px;
  }
  
  .d-istrict .vector {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 11px;
    width: 20px;
  }
  
  .d-istrict .text-wrapper-16 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 51px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .d-istrict .img {
    height: 52px;
    left: 675px;
    position: absolute;
    top: 0;
    width: 144px;
  }
  
  .d-istrict .group-11 {
    height: 584px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 943px;
  }
  
  .d-istrict .group-12 {
    height: 584px;
    left: 24;
    position: absolute;
    top: 0;
    width: 916px;
  }
  
  .d-istrict .group-13 {
    height: 283px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 296px;
  }
  
  .d-istrict .group-14 {
    height: 283px;
    left: 310px;
    position: absolute;
    top: -3px;
    width: 296px;
  }
  
  .d-istrict .group-15 {
    height: 283px;
    left: 623px;
    position: absolute;
    top: -3px;
    width: 296px;
  }
  
  .d-istrict .group-16 {
    height: 283px;
    left: -3px;
    position: absolute;
    top: 304px;
    width: 296px;
  }
  
  .d-istrict .group-17 {
    height: 283px;
    left: 310px;
    position: absolute;
    top: 304px;
    width: 296px;
  }
  
  .d-istrict .group-18 {
    height: 283px;
    left: 623px;
    position: absolute;
    top: 304px;
    width: 296px;
  }
  
  .d-istrict .rectangle-wrapper {
    background-color: #d9d9d9;
    border-radius: 20px;
    height: 550px;
    left: 928px;
    position: absolute;
    top: 5px;
    width: 15px;
  }
  
  .d-istrict .rectangle {
    background-color: #3c3b3b80;
    border-radius: 20px;
    height: 95px;
    left: 2px;
    position: relative;
    top: 45px;
    width: 11px;
  }
  
  .d-istrict .group-19 {
    height: 791px;
    left: 0;
    position: absolute;
    top: 0;
    width: 271px;
  }
  
  .d-istrict .group-20 {
    background-color: #4270e7f2;
    border-radius: 6px;
    height: 791px;
  }
  
  .d-istrict .group-21 {
    height: 555px;
    left: 8px;
    position: relative;
    top: 20px;
    width: 260px;
  }
  
  .d-istrict .side-nav-devider {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    position: absolute;
    top: 356px;
    width: 256px;
  }
  
  .d-istrict .tasks {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 106px;
    width: 185px;
  }
  
  .d-istrict .text-medium-13-darkblue {
    left: 34px !important;
    position: absolute !important;
    top: 0 !important;
    width: 151px !important;
  }
  
  .d-istrict .text-medium-instance {
    color: #ffffff !important;
    font-size: 16px !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .d-istrict .icon-instance-node {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 20px !important;
  }
  
  .d-istrict .group-22 {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 433px;
    width: 92px;
  }
  
  .d-istrict .settings {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 58px;
  }
  
  .d-istrict .text-medium-13-darkblue-instance {
    width: unset !important;
  }
  
  .d-istrict .design-component-instance-node {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 58px !important;
  }
  
  .d-istrict .text-wrapper-17 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 17px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 52px;
  }
  
  .d-istrict .group-23 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 157px;
    width: 185px;
  }
  
  .d-istrict .group-24 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 256px;
    width: 91px;
  }
  
  .d-istrict .chat {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .d-istrict .group-25 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 306px;
    width: 99px;
  }
  
  .d-istrict .deals {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .d-istrict .text-wrapper-18 {
    color: #ffffff;
    font-family: var(--medium-13px-font-family);
    font-size: var(--medium-13px-font-size);
    font-style: var(--medium-13px-font-style);
    font-weight: var(--medium-13px-font-weight);
    left: 51px;
    letter-spacing: var(--medium-13px-letter-spacing);
    line-height: var(--medium-13px-line-height);
    position: absolute;
    top: 382px;
    width: 56px;
  }
  
  .d-istrict .group-26 {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 483px;
    width: 64px;
  }
  
  .d-istrict .text-medium-13-darkblue-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .d-istrict .text-medium-2 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 30px !important;
  }
  
  .d-istrict .dashboard {
    height: 21px;
    left: 14px;
    position: absolute;
    top: 51px;
    width: 129px;
  }
  
  .d-istrict .text-medium-13-accent {
    left: 36px !important;
    position: absolute !important;
    top: 0 !important;
    width: 93px !important;
  }
  
  .d-istrict .group-27 {
    height: 40px;
    left: 2px;
    position: absolute;
    top: 199px;
    width: 236px;
  }
  
  .d-istrict .overlap-group-3 {
    height: 40px;
    position: relative;
  }
  
  .d-istrict .rectangle-2 {
    background-color: #ffffff;
    border-radius: 6px;
    height: 40px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 235px;
  }
  
  .d-istrict .group-28 {
    height: 20px;
    left: 16px;
    position: absolute;
    top: 10px;
    width: 94px;
  }
  
  .d-istrict .contacts {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .d-istrict .text-medium-3 {
    color: #413ef7 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .d-istrict .rectangle-3 {
    background-color: #413ef7;
    border-radius: 20px 0px 0px 10px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .d-istrict .group-29 {
    height: 22px;
    left: 19px;
    position: absolute;
    top: 533px;
    width: 90px;
  }
  
  .d-istrict .log-out {
    height: 22px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 22px !important;
  }
  
  .d-istrict .text-medium-instance-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 2px;
    width: 56px;
  }
  
  .d-istrict .text-medium-4 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 56px !important;
  }
  
  .d-istrict .group-30 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .d-istrict .overlap-2 {
    height: 100px;
    position: relative;
  }
  
  .d-istrict .HEADER-wrapper {
    height: 100px;
    left: 277px;
    position: absolute;
    top: 0;
    width: 998px;
  }
  
  .d-istrict .HEADER {
    height: 100px;
    width: 1000px;
  }
  
  .d-istrict .overlap-3 {
    height: 100px;
    position: relative;
    width: 998px;
  }
  
  .d-istrict .header {
    background-color: #ffffff;
    box-shadow: 0px -6.46px 14.53px #00000021, 0px -1.21px 3.63px #0000001c;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 998px;
  }
  
  .d-istrict .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 16.14px;
    left: 65px;
    position: absolute;
    top: 17px;
  }
  
  .d-istrict .headertabs-default {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16.14px;
    padding: 3.03px 0px;
    position: relative;
  }
  
  .d-istrict .headertabitem {
    flex: 0 0 auto;
    height: 32.29px;
    position: relative;
  }
  
  .d-istrict .search-bar {
    border-radius: 6px;
    height: 61px;
    left: 775px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    width: 178px;
  }
  
  .d-istrict .user-name {
    height: 44px;
    left: -1px;
    position: relative;
    top: 9px;
    width: 177px;
  }
  
  .d-istrict .photo {
    height: 44px;
    left: 131px;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .d-istrict .overlap-group-4 {
    border-radius: 23.5px;
    height: 47px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 47px;
  }
  
  .d-istrict .avatar-man-header {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 40px;
  }
  
  .d-istrict .profile-circle {
    border: 1.5px solid;
    border-color: var(--grayscale-divider);
    border-radius: 23.5px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 47px;
  }
  
  .d-istrict .name {
    color: var(--grayscale-black);
    font-family: var(--semibold-14-0-2-px-font-family);
    font-size: var(--semibold-14-0-2-px-font-size);
    font-style: var(--semibold-14-0-2-px-font-style);
    font-weight: var(--semibold-14-0-2-px-font-weight);
    left: 30px;
    letter-spacing: var(--semibold-14-0-2-px-letter-spacing);
    line-height: var(--semibold-14-0-2-px-line-height);
    position: absolute;
    text-align: right;
    top: 13px;
    white-space: nowrap;
    width: 87px;
  }
  
  .d-istrict .icon-notifications {
    height: 16px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 16px;
  }
  
  .d-istrict .overlap-4 {
    height: 18px;
    left: 1px;
    position: relative;
    top: -2px;
  }
  
  .d-istrict .vector-2 {
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .d-istrict .new {
    background-color: #413ef7;
    border: 1.5px solid;
    border-color: var(--grayscale-bg);
    border-radius: 4.5px;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 9px;
  }
  
  .d-istrict .text-wrapper-19 {
    color: #413ef7;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 54px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 90px;
  }
  
  .d-istrict .group-31 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 279px;
  }
  
  .d-istrict .overlap-5 {
    background-color: #ffffff;
    height: 100px;
    position: relative;
    width: 277px;
  }
  
  .d-istrict .text-wrapper-20 {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 119px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 40px;
    white-space: nowrap;
  }
  