.taluka {
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .taluka .overlap-wrapper {
    background-color: #ededed;
    height: 950px;
    width: 1310px;
  }
  
  .taluka .overlap {
    height: 916px;
    left: 18px;
    position: relative;
    width: 1275px;
  }
  
  .taluka .div {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 102px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 37px;
    width: 45px;
  }
  
  .taluka .group {
    height: 916px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .taluka .group-2 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .taluka .HEADER {
    height: 100px;
    left: 277px;
    position: absolute;
    top: 0;
    width: 1000px;
  }
  
  .taluka .overlap-group {
    height: 100px;
    position: relative;
    width: 998px;
  }
  
  .taluka .header {
    background-color: #ffffff;
    box-shadow: 0px -6.46px 14.53px #00000021, 0px -1.21px 3.63px #0000001c;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 998px;
  }
  
  .taluka .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 16.14px;
    left: 65px;
    position: absolute;
    top: 17px;
  }
  
  .taluka .headertabs-default {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16.14px;
    padding: 3.03px 0px;
    position: relative;
  }
  
  .taluka .headertabitem {
    flex: 0 0 auto;
    height: 32.29px;
    position: relative;
  }
  
  .taluka .search-bar {
    border-radius: 6px;
    height: 61px;
    left: 775px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    width: 178px;
  }
  
  .taluka .user-name {
    height: 44px;
    left: -1px;
    position: relative;
    top: 9px;
    width: 558px;
  }
  
  .taluka .photo {
    height: 44px;
    left: 131px;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .taluka .overlap-group-2 {
    border-radius: 23.5px;
    height: 47px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 47px;
  }
  
  .taluka .avatar-man-header {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 40px;
  }
  
  .taluka .profile-circle {
    border: 1.5px solid;
    border-color: var(--grayscale-divider);
    border-radius: 23.5px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 47px;
  }
  
  .taluka .avatar-woman {
    height: 41px;
    left: -7648px;
    position: absolute;
    top: 457px;
    width: 41px;
  }
  
  .taluka .name {
    color: var(--grayscale-black);
    font-family: var(--semibold-14-0-2-px-font-family);
    font-size: var(--semibold-14-0-2-px-font-size);
    font-style: var(--semibold-14-0-2-px-font-style);
    font-weight: var(--semibold-14-0-2-px-font-weight);
    left: 30px;
    letter-spacing: var(--semibold-14-0-2-px-letter-spacing);
    line-height: var(--semibold-14-0-2-px-line-height);
    position: absolute;
    text-align: right;
    top: 13px;
    white-space: nowrap;
    width: 87px;
  }
  
  .taluka .icon-notifications {
    height: 16px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 16px;
  }
  
  .taluka .overlap-2 {
    height: 18px;
    left: 1px;
    position: relative;
    top: -2px;
  }
  
  .taluka .vector {
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .taluka .new {
    background-color: #413ef7;
    border: 1.5px solid;
    border-color: var(--grayscale-bg);
    border-radius: 4.5px;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 9px;
  }
  
  .taluka .text-wrapper-2 {
    color: #413ef7;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 51px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 90px;
  }
  
  .taluka .rectangle {
    background-color: #ffffff;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 277px;
  }
  
  .taluka .group-wrapper {
    height: 791px;
    left: 0;
    position: absolute;
    top: 125px;
    width: 271px;
  }
  
  .taluka .div-wrapper {
    background-color: #4270e7f2;
    border-radius: 6px;
    height: 791px;
  }
  
  .taluka .group-3 {
    height: 552px;
    left: 8px;
    position: relative;
    top: 23px;
    width: 260px;
  }
  
  .taluka .side-nav-devider {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    position: absolute;
    top: 356px;
    width: 256px;
  }
  
  .taluka .tasks {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 106px;
    width: 185px;
  }
  
  .taluka .text-medium-13-darkblue {
    left: 34px !important;
    position: absolute !important;
    top: 0 !important;
    width: 151px !important;
  }
  
  .taluka .text-medium-instance {
    color: #ffffff !important;
    font-size: 16px !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .taluka .icon-instance-node {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 20px !important;
  }
  
  .taluka .group-4 {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 430px;
    width: 92px;
  }
  
  .taluka .settings {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 58px;
  }
  
  .taluka .text-medium-13-darkblue-instance {
    width: unset !important;
  }
  
  .taluka .design-component-instance-node {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 58px !important;
  }
  
  .taluka .text-wrapper-3 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 17px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 52px;
  }
  
  .taluka .group-5 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 157px;
    width: 185px;
  }
  
  .taluka .group-6 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 206px;
    width: 94px;
  }
  
  .taluka .contacts {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .taluka .group-7 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 306px;
    width: 99px;
  }
  
  .taluka .deals {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .taluka .text-wrapper-4 {
    color: #ffffff;
    font-family: var(--medium-13px-font-family);
    font-size: var(--medium-13px-font-size);
    font-style: var(--medium-13px-font-style);
    font-weight: var(--medium-13px-font-weight);
    left: 51px;
    letter-spacing: var(--medium-13px-letter-spacing);
    line-height: var(--medium-13px-line-height);
    position: absolute;
    top: 382px;
    width: 56px;
  }
  
  .taluka .group-8 {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 480px;
    width: 64px;
  }
  
  .taluka .text-medium-13-darkblue-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .taluka .text-medium-2 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 30px !important;
  }
  
  .taluka .dashboard {
    height: 21px;
    left: 14px;
    position: absolute;
    top: 51px;
    width: 129px;
  }
  
  .taluka .text-medium-13-accent {
    left: 36px !important;
    position: absolute !important;
    top: 0 !important;
    width: 93px !important;
  }
  
  .taluka .overlap-group-wrapper {
    height: 40px;
    left: 3px;
    position: absolute;
    top: 250px;
    width: 237px;
  }
  
  .taluka .overlap-group-3 {
    height: 40px;
    position: relative;
  }
  
  .taluka .rectangle-2 {
    background-color: #ffffff;
    border-radius: 6px;
    height: 40px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 235px;
  }
  
  .taluka .group-9 {
    height: 20px;
    left: 16px;
    position: absolute;
    top: 10px;
    width: 91px;
  }
  
  .taluka .chat {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .taluka .text-medium-3 {
    color: #413ef7 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .taluka .rectangle-3 {
    background-color: #413ef7;
    border-radius: 20px 0px 0px 10px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .taluka .group-10 {
    height: 22px;
    left: 19px;
    position: absolute;
    top: 530px;
    width: 90px;
  }
  
  .taluka .log-out {
    height: 22px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 22px !important;
  }
  
  .taluka .text-medium-instance-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 2px;
    width: 56px;
  }
  
  .taluka .text-medium-4 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 56px !important;
  }
  
  .taluka .group-11 {
    background-color: #ffffff;
    height: 791px;
    left: 130px;
    position: absolute;
    top: 0px;
    width: 976px;
  }
  
  .taluka .group-12 {
    height: 738px;
    left: 20px;
    position: relative;
    top: 20px;
    width: 947px;
  }
  
  .taluka .group-13 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 916px;
  }
  
  .taluka .group-14 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 362px;
  }
  
  .taluka .overlap-group-4 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #cdcdcd;
    border-radius: 50px;
    height: 44px;
    position: relative;
    width: 360px;
  }
  
  .taluka .img {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 11px;
    width: 20px;
  }
  
  .taluka .text-wrapper-5 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 51px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .taluka .group-15 {
    height: 52px;
    left: 776px;
    position: absolute;
    top: 0;
    width: 144px;
  }
  
  .taluka .group-16 {
    height: 662px;
    left: 2px;
    position: absolute;
    top: 76px;
    width: 945px;
  }
  
  .taluka .group-17 {
    height: 662px;
    left: 0;
    position: absolute;
    top: 0;
    width: 917px;
  }
  
  .taluka .group-18 {
    height: 200px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 290px;
  }
  
  .taluka .overlap-3 {
    height: 200px;
    position: relative;
    width: 310px;
  }
  
  .taluka .group-19 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 310px;
  }
  
  .taluka .overlap-group-5 {
    background-color: #ffffff;
    border: 0.5px solid;
    border-color: #999999;
    border-radius: 15px;
    height: 200px;
    position: relative;
    width: 290px;
  }
  
  .taluka .text-wrapper-6 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 122px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-7 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 152px;
    white-space: nowrap;
  }
  
  .taluka .overlap-group-6 {
    height: 25px;
    left: 26px;
    position: absolute;
    top: 150px;
    width: 114px;
  }
  
  .taluka .text-wrapper-8 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 21px;
    opacity: 0.76;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 114px;
  }
  
  .taluka .text-wrapper-9 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 105px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-10 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 26px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 122px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-11 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 26px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 94px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-12 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 130px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 94px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-13 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 130px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 122px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-14 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 94px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-15 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 134px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 36px;
    white-space: nowrap;
  }
  
  .taluka .group-20 {
    height: 45px;
    left: 25px;
    position: absolute;
    top: 25px;
    width: 47px;
  }
  
  .taluka .overlap-4 {
    background: linear-gradient(180deg, rgb(65, 62, 247) 0%, rgba(115.4, 149.23, 236.73, 0.95) 100%);
    border: 0.4px solid;
    border-color: #00000080;
    border-radius: 22.5px;
    height: 45px;
    position: relative;
    width: 45px;
  }
  
  .taluka .text-wrapper-16 {
    -webkit-text-stroke: 0.4px #00000080;
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 8px;
  }
  
  .taluka .group-21 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 231px;
    width: 290px;
  }
  
  .taluka .overlap-5 {
    height: 200px;
    position: relative;
    width: 312px;
  }
  
  .taluka .group-22 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 312px;
    background-color: white;
  }
  
  .taluka .overlap-6 {
    height: 21px;
    left: 145px;
    position: absolute;
    top: 152px;
    width: 48px;
  }
  
  .taluka .text-wrapper-17 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 0;
    white-space: nowrap;
  }
  
  .taluka .group-23 {
    height: 200px;
    left: 313px;
    position: absolute;
    top: 231px;
    width: 599px;
  }
  
  .taluka .overlap-7 {
    height: 200px;
    position: relative;
    width: 619px;
  }
  
  .taluka .overlap-8 {
    height: 21px;
    left: 145px;
    position: absolute;
    top: 123px;
    width: 67px;
  }
  
  .taluka .text-wrapper-18 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 150px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-19 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 132px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 36px;
    white-space: nowrap;
  }
  
  .taluka .group-24 {
    height: 200px;
    left: 309px;
    position: absolute;
    top: 0;
    width: 310px;
  }
  
  .taluka .text-wrapper-20 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 144px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 124px;
    white-space: nowrap;
  }
  
  .taluka .group-25 {
    height: 45px;
    left: 334px;
    position: absolute;
    top: 25px;
    width: 47px;
  }
  
  .taluka .text-wrapper-21 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 9px;
  }
  
  .taluka .group-26 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 462px;
    width: 290px;
  }
  
  .taluka .text-wrapper-22 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 134px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 38px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-23 {
    -webkit-text-stroke: 0.4px #00000080;
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 9px;
  }
  
  .taluka .group-27 {
    height: 200px;
    left: 313px;
    position: absolute;
    top: 462px;
    width: 290px;
  }
  
  .taluka .text-wrapper-24 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 130px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 38px;
    white-space: nowrap;
  }
  
  .taluka .group-28 {
    height: 200px;
    left: 626px;
    position: absolute;
    top: 462px;
    width: 290px;
  }
  
  .taluka .group-29 {
    height: 200px;
    left: 314px;
    position: absolute;
    top: 0;
    width: 290px;
  }
  
  .taluka .group-30 {
    height: 200px;
    left: 627px;
    position: absolute;
    top: 0;
    width: 290px;
  }
  
  .taluka .text-wrapper-25 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 146px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 150px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-26 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 134px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 40px;
    white-space: nowrap;
  }
  
  .taluka .text-wrapper-27 {
    -webkit-text-stroke: 0.4px #00000080;
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 600;
    left: 12px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 8px;
  }
  
  .taluka .rectangle-wrapper {
    background-color: #d9d9d9;
    border-radius: 20px;
    height: 600px;
    left: 930px;
    position: absolute;
    top: 24px;
    width: 15px;
  }
  
  .taluka .rectangle-4 {
    background-color: #3c3b3b80;
    border-radius: 20px;
    height: 104px;
    left: 2px;
    position: relative;
    top: 49px;
    width: 11px;
  }
  