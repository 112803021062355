.reports {
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .reports .overlap-wrapper {
    background-color: #ededed;
    height: 950px;
    width: 1310px;
  }
  
  .reports .overlap {
    height: 916px;
    left: 18px;
    position: relative;
    width: 1275px;
  }
  
  .reports .div {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 102px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 37px;
    width: 45px;
  }
  
  .reports .line {
    height: 2px;
    left: 412px;
    position: absolute;
    top: 588px;
    width: 60px;
  }
  
  .reports .group {
    height: 916px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .reports .group-2 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .reports .HEADER {
    height: 100px;
    left: 277px;
    position: absolute;
    top: 0;
    width: 1000px;
  }
  
  .reports .overlap-group {
    height: 100px;
    position: relative;
    width: 998px;
  }
  
  .reports .header {
    background-color: #ffffff;
    box-shadow: 0px -6.46px 14.53px #00000021, 0px -1.21px 3.63px #0000001c;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 998px;
  }
  
  .reports .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 16.14px;
    left: 65px;
    position: absolute;
    top: 17px;
  }
  
  .reports .headertabs-default {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16.14px;
    padding: 3.03px 0px;
    position: relative;
  }
  
  .reports .headertabitem {
    flex: 0 0 auto;
    height: 32.29px;
    position: relative;
  }
  
  .reports .search-bar {
    border-radius: 6px;
    height: 61px;
    left: 775px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    width: 178px;
  }
  
  .reports .user-name {
    height: 44px;
    left: -1px;
    position: relative;
    top: 9px;
    width: 177px;
  }
  
  .reports .photo {
    height: 44px;
    left: 131px;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .reports .overlap-group-2 {
    border-radius: 23.5px;
    height: 47px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 47px;
  }
  
  .reports .avatar-man-header {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 40px;
  }
  
  .reports .profile-circle {
    border: 1.5px solid;
    border-color: var(--grayscale-divider);
    border-radius: 23.5px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 47px;
  }
  
  .reports .name {
    color: var(--grayscale-black);
    font-family: var(--semibold-14-0-2-px-font-family);
    font-size: var(--semibold-14-0-2-px-font-size);
    font-style: var(--semibold-14-0-2-px-font-style);
    font-weight: var(--semibold-14-0-2-px-font-weight);
    left: 30px;
    letter-spacing: var(--semibold-14-0-2-px-letter-spacing);
    line-height: var(--semibold-14-0-2-px-line-height);
    position: absolute;
    text-align: right;
    top: 13px;
    white-space: nowrap;
    width: 87px;
  }
  
  .reports .icon-notifications {
    height: 16px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 16px;
  }
  
  .reports .overlap-2 {
    height: 18px;
    left: 1px;
    position: relative;
    top: -2px;
  }
  
  .reports .vector {
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .reports .new {
    background-color: #413ef7;
    border: 1.5px solid;
    border-color: var(--grayscale-bg);
    border-radius: 4.5px;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 9px;
  }
  
  .reports .text-wrapper-2 {
    color: #413ef7;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 44px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 103px;
  }
  
  .reports .rectangle {
    background-color: #ffffff;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 277px;
  }
  
  .reports .group-wrapper {
    background-color: #ffffff;
    height: 791px;
    left: 299px;
    position: absolute;
    top: 125px;
    width: 976px;
  }
  
  .reports .group-3 {
    height: 520px;
    left: 160px;
    position: relative;
    top: 45px;
    width: 916px;
  }
  
  .reports .overlap-group-wrapper {
    height: 184px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 274px;
  }
  
  .reports .overlap-3 {
    height: 184px;
    position: relative;
    width: 276px;
  }
  
  .reports .rectangle-2 {
    background-color: #ffffff;
    border: 0.4px solid;
    border-color: #00000080;
    border-radius: 8px;
    box-shadow: 0px 0px 3px #00000040;
    height: 184px;
    left: 0;
    position: absolute;
    top: 0;
    width: 270px;
  }
  
  .reports .text-wrapper-3 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 17px;
    font-weight: 400;
    height: 22px;
    left: 94px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 118px;
    width: 132px;
  }
  
  .reports .text-wrapper-4 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 22px;
    left: 49px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 118px;
    width: 30px;
  }
  
  .reports .div-wrapper {
    height: 78px;
    left: 0;
    position: absolute;
    top: 0;
    width: 276px;
  }
  
  .reports .overlap-4 {
    background: linear-gradient(180deg, rgb(130, 158.87, 233.54) 0%, rgb(66, 112, 231) 100%);
    border-radius: 8px 8px 0px 0px;
    height: 78px;
    position: relative;
    width: 270px;
    z-index: 1;
    left: 0;
  }
  
  .reports .radial-progress {
    height: 40px;
    left: 205px;
    position: absolute;
    top: 19px;
    width: 40px;
  }
  
  .reports .element-wrapper {
    border: 7px solid;
    border-color: #ffffff;
    border-radius: 21.68px;
    height: 43px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 43px;
  }
  
  .reports .element {
    height: 17px;
    left: 11px;
    position: absolute;
    top: -7px;
    width: 23px;
  }
  
  .reports .today-s-updates {
    color: #ffffff;
    font-family: "Mulish", Helvetica;
    font-size: 17px;
    font-weight: 400;
    height: 44px;
    left: 22px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 13px;
  }
  
  .reports .img {
    height: 51px;
    left: 102px;
    object-fit: cover;
    position: absolute;
    top: 14px;
    width: 1px;
  }
  
  .reports .text-wrapper-5 {
    color: #ffffff;
    font-family: "Mulish", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 22px;
    left: 122px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 13px;
    width: 64px;
  }
  
  .reports .text-wrapper-6 {
    color: #ffffff;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    height: 22px;
    left: 122px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 35px;
    width: 43px;
  }
  
  .reports .group-4 {
    height: 184px;
    left: 324px;
    position: absolute;
    top: 2px;
    width: 274px;
  }
  
  .reports .overlap-5 {
    background-color: #ffffff;
    border: 0.4px solid;
    border-color: #00000080;
    border-radius: 8px;
    box-shadow: 0px 0px 3px #00000040;
    height: 184px;
    position: relative;
    width: 270px;
  }
  
  .reports .overlap-group-3 {
    background: linear-gradient(180deg, rgb(130, 158.87, 233.54) 0%, rgb(66, 112, 231) 100%);
    border-radius: 8px 8px 0px 0px;
    height: 78px;
    left: 0;
    position: absolute;
    top: 0;
    width: 270px;
  }
  
  .reports .text-wrapper-7 {
    color: #ffffff;
    font-family: "Mulish", Helvetica;
    font-size: 17px;
    font-weight: 400;
    height: 22px;
    left: 14px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 20px;
    white-space: nowrap;
  }
  
  .reports .text-wrapper-8 {
    color: #999999;
    font-family: var(--bold-40-1-px-font-family);
    font-size: var(--bold-40-1-px-font-size);
    font-style: var(--bold-40-1-px-font-style);
    font-weight: var(--bold-40-1-px-font-weight);
    left: 127px;
    letter-spacing: var(--bold-40-1-px-letter-spacing);
    line-height: var(--bold-40-1-px-line-height);
    position: absolute;
    text-align: center;
    top: 106px;
  }
  
  .reports .group-5 {
    height: 184px;
    left: 647px;
    position: absolute;
    top: 0;
    width: 271px;
  }
  
  .reports .overlap-6 {
    height: 184px;
    position: relative;
  }
  
  .reports .rectangle-3 {
    background-color: #ffffff;
    border: 0.4px solid;
    border-color: #00000080;
    border-radius: 8px;
    box-shadow: 0px 0px 3px #00000040;
    height: 184px;
    left: 0;
    position: absolute;
    top: 0;
    width: 269px;
  }
  
  .reports .group-6 {
    height: 78px;
    left: 0;
    position: absolute;
    top: 0;
    width: 271px;
  }
  
  .reports .overlap-group-4 {
    background: linear-gradient(180deg, rgb(130, 158.87, 233.54) 0%, rgb(66, 112, 231) 100%);
    border-radius: 8px 8px 0px 0px;
    height: 78px;
    position: relative;
    width: 269px;
  }
  
  .reports .text-wrapper-9 {
    color: #ffffff;
    font-family: "Mulish", Helvetica;
    font-size: 17px;
    font-weight: 400;
    height: 22px;
    left: 16px;
    letter-spacing: 0;
    line-height: 22px;
    position: absolute;
    top: 22px;
    white-space: nowrap;
  }
  
  .reports .text-wrapper-10 {
    color: #999999;
    font-family: var(--bold-40-1-px-font-family);
    font-size: var(--bold-40-1-px-font-size);
    font-style: var(--bold-40-1-px-font-style);
    font-weight: var(--bold-40-1-px-font-weight);
    left: 135px;
    letter-spacing: var(--bold-40-1-px-letter-spacing);
    line-height: var(--bold-40-1-px-line-height);
    position: absolute;
    text-align: center;
    top: 106px;
  }
  
  .reports .group-7 {
    height: 284px;
    left: 0;
    position: absolute;
    top: 236px;
    /* width: 458px; */
  }
  
  .reports .overlap-7 {
    height: 284px;
    position: relative;
    width: 436px;
  }
  
  .reports .rectangle-4 {
    background-color: #ffffff;
    border: 0.4px solid;
    border-color: #00000080;
    border-radius: 8px;
    box-shadow: 0px 0px 3px #00000040;
    height: 284px;
    left: 0;
    position: absolute;
    top: 0;
    width: 435px;
  }
  
  .reports .chevron-down {
    height: 18px !important;
    left: 140px !important;
    position: absolute !important;
    top: 121px !important;
    width: 18px !important;
  }
  
  .reports .chevron-down-4 {
    height: 18px !important;
    left: 402px !important;
    position: absolute !important;
    top: 15px !important;
    width: 18px !important;
  }
  
  .reports .chevron-down-1 {
    height: 18px !important;
    left: 140px !important;
    position: absolute !important;
    top: 172px !important;
    width: 18px !important;
  }
  
  .reports .text-wrapper-11 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 15px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
  }
  
  .reports .text-wrapper-12 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
  }
  
  .reports .text-wrapper-13 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 92px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
  }
  
  .reports .text-wrapper-14 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 163px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 60px;
  }
  
  .reports .text-wrapper-15 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 358px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
  }
  
  .reports .line-2 {
    height: 1px;
    left: 1px;
    position: absolute;
    top: 90px;
    width: 435px;
  }
  
  .reports .text-wrapper-16 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 25px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 115px;
  }
  
  .reports .text-wrapper-17 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 25px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 164px;
  }
  
  .reports .text-wrapper-18 {
    color: #00000080;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 25px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 135px;
  }
  
  .reports .text-wrapper-19 {
    color: #00000080;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 25px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 184px;
  }
  
  .reports .text-wrapper-20 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 351px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 115px;
  }
  
  .reports .text-wrapper-21 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 351px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 164px;
  }
  
  .reports .group-8 {
    height: 284px;
    left: 482px;
    position: absolute;
    top: 236px;
    width: 442px;
  }
  
  .reports .overlap-8 {
    background-color: #ffffff;
    border: 0.4px solid;
    border-color: #00000080;
    border-radius: 8px;
    box-shadow: 0px 0px 3px #00000040;
    height: 284px;
    position: relative;
    width: 434px;
  }
  
  .reports .overlap-9 {
    height: 207px;
    left: 2px;
    position: absolute;
    top: 19px;
    width: 411px;
  }
  
  .reports .group-9 {
    height: 206px;
    left: 41px;
    position: absolute;
    top: 1px;
    width: 370px;
  }
  
  .reports .overlap-10 {
    height: 133px;
    left: 0;
    position: absolute;
    top: 73px;
    width: 313px;
  }
  
  .reports .group-10 {
    height: 133px;
    left: 0;
    position: absolute;
    top: 0;
    width: 313px;
  }
  
  .reports .overlap-11 {
    height: 133px;
    position: relative;
  }
  
  .reports .group-11 {
    height: 99px;
    left: 0;
    position: absolute;
    top: 0;
    width: 23px;
  }
  
  .reports .overlap-group-5 {
    height: 99px;
    position: relative;
  }
  
  .reports .vector-2 {
    height: 92px;
    left: 22px;
    position: absolute;
    top: 5px;
    width: 1px;
  }
  
  .reports .group-12 {
    height: 9px;
    left: 13px;
    position: absolute;
    top: 90px;
    width: 12px;
  }
  
  .reports .vector-3 {
    height: 1px;
    left: 7px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 3px;
  }
  
  .reports .text-wrapper-22 {
    color: #999999;
    font-family: "Mulish", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: right;
    top: 0;
    white-space: nowrap;
    width: 5px;
  }
  
  .reports .group-13 {
    height: 9px;
    left: 13px;
    position: absolute;
    top: 72px;
    width: 12px;
  }
  
  .reports .vector-4 {
    height: 1px;
    left: 7px;
    object-fit: cover;
    position: absolute;
    top: 1px;
    width: 3px;
  }
  
  .reports .group-14 {
    height: 9px;
    left: 8px;
    position: absolute;
    top: 44px;
    width: 16px;
  }
  
  .reports .vector-5 {
    height: 1px;
    left: 12px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 3px;
  }
  
  .reports .text-wrapper-23 {
    color: #999999;
    font-family: "Mulish", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: right;
    top: 0;
    white-space: nowrap;
    width: 9px;
  }
  
  .reports .group-15 {
    height: 9px;
    left: 8px;
    position: absolute;
    top: 21px;
    width: 16px;
  }
  
  .reports .group-16 {
    height: 9px;
    left: 0;
    position: absolute;
    top: 0;
    width: 25px;
  }
  
  .reports .vector-6 {
    height: 1px;
    left: 20px;
    object-fit: cover;
    position: absolute;
    top: 4px;
    width: 3px;
  }
  
  .reports .text-wrapper-24 {
    color: #999999;
    font-family: "Mulish", Helvetica;
    font-size: 10px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    text-align: right;
    top: 0;
    white-space: nowrap;
    width: 17px;
  }
  
  .reports .group-17 {
    height: 124px;
    left: 22px;
    position: absolute;
    top: 9px;
    width: 292px;
  }
  
  .reports .overlap-12 {
    height: 124px;
    position: relative;
    width: 298px;
  }
  
  .reports .vector-7 {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 88px;
    width: 292px;
  }
  
  .reports .group-18 {
    height: 124px;
    left: 21px;
    position: absolute;
    top: 0;
    width: 277px;
  }
  
  .reports .group-19 {
    height: 88px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .reports .group-20 {
    height: 3px;
    left: 15px;
    position: absolute;
    top: 88px;
    width: 1px;
  }
  
  .reports .text-wrapper-25 {
    color: #747474;
    font-family: "Mulish", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 8px;
    letter-spacing: 0;
    line-height: 8px;
    position: absolute;
    text-align: center;
    top: 116px;
    white-space: nowrap;
  }
  
  .reports .overlap-13 {
    height: 56px;
    left: 57px;
    position: absolute;
    top: 35px;
    width: 30px;
  }
  
  .reports .img-2 {
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .reports .vector-8 {
    height: 3px;
    left: 14px;
    position: absolute;
    top: 53px;
    width: 1px;
  }
  
  .reports .text-wrapper-26 {
    color: #747474;
    font-family: "Mulish", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 60px;
    letter-spacing: 0;
    line-height: 8px;
    position: absolute;
    text-align: center;
    top: 116px;
    white-space: nowrap;
  }
  
  .reports .vector-9 {
    height: 23px;
    left: 114px;
    position: absolute;
    top: 65px;
    width: 30px;
  }
  
  .reports .vector-10 {
    height: 3px;
    left: 129px;
    position: absolute;
    top: 88px;
    width: 1px;
  }
  
  .reports .text-wrapper-27 {
    color: #747474;
    font-family: "Mulish", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 120px;
    letter-spacing: 0;
    line-height: 8px;
    position: absolute;
    text-align: center;
    top: 116px;
    white-space: nowrap;
  }
  
  .reports .overlap-group-6 {
    height: 56px;
    left: 171px;
    position: absolute;
    top: 35px;
    width: 30px;
  }
  
  .reports .text-wrapper-28 {
    color: #747474;
    font-family: "Mulish", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 178px;
    letter-spacing: 0;
    line-height: 8px;
    position: absolute;
    text-align: center;
    top: 116px;
    white-space: nowrap;
  }
  
  .reports .overlap-14 {
    height: 84px;
    left: 228px;
    position: absolute;
    top: 7px;
    width: 39px;
  }
  
  .reports .vector-11 {
    height: 81px;
    left: 0;
    position: absolute;
    top: 0;
    width: 39px;
  }
  
  .reports .vector-12 {
    height: 3px;
    left: 20px;
    position: absolute;
    top: 81px;
    width: 1px;
  }
  
  .reports .text-wrapper-29 {
    color: #747474;
    font-family: "Mulish", Helvetica;
    font-size: 6px;
    font-weight: 400;
    left: 240px;
    letter-spacing: 0;
    line-height: 8px;
    position: absolute;
    text-align: center;
    top: 116px;
    white-space: nowrap;
  }
  
  .reports .group-21 {
    height: 15px;
    left: 107px;
    position: absolute;
    top: 104px;
    width: 17px;
  }
  
  .reports .overlap-15 {
    background: linear-gradient(180deg, rgb(65, 62, 247) 0%, rgb(162, 183, 237) 100%);
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .reports .text-wrapper-30 {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 10px;
    font-weight: 600;
    left: 4px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 1px;
    white-space: nowrap;
  }
  
  .reports .group-22 {
    height: 15px;
    left: 50px;
    position: absolute;
    top: 104px;
    width: 17px;
  }
  
  .reports .overlap-16 {
    background: linear-gradient(180deg, rgb(65, 62, 247) 0%, rgba(161.76, 182.66, 236.73, 0.95) 100%);
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .reports .group-23 {
    height: 15px;
    left: 284px;
    position: absolute;
    top: 104px;
    width: 17px;
  }
  
  .reports .overlap-17 {
    background: linear-gradient(180deg, rgb(65, 62, 247) 0%, rgba(144.63, 168.83, 231.41, 0.95) 100%);
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .reports .group-24 {
    height: 15px;
    left: 222px;
    position: absolute;
    top: 104px;
    width: 17px;
  }
  
  .reports .overlap-18 {
    background: linear-gradient(180deg, rgb(65, 62, 247) 0%, rgba(135.51, 163.43, 235.66, 0.95) 100%);
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .reports .group-25 {
    height: 15px;
    left: 165px;
    position: absolute;
    top: 104px;
    width: 17px;
  }
  
  .reports .overlap-19 {
    background: linear-gradient(180deg, rgb(65, 62, 247) 0%, rgba(135.26, 162.66, 233.54, 0.95) 100%);
    border-radius: 7.5px;
    height: 15px;
    position: relative;
    width: 15px;
  }
  
  .reports .chevron-down-4-instance {
    height: 18px !important;
    left: 352px !important;
    position: absolute !important;
    top: 0 !important;
    width: 18px !important;
  }
  
  .reports .text-wrapper-31 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .reports .text-wrapper-32 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 339px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .reports .text-wrapper-33 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 114px;
    transform: rotate(-90deg);
  }
  
  .reports .text-wrapper-34 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 15px;
    font-weight: 400;
    left: 92px;
    letter-spacing: 0;
    line-height: normal;
    opacity: 0.8;
    position: absolute;
    top: 240px;
  }
  
  .reports .group-26 {
    height: 791px;
    left: 0;
    position: absolute;
    top: 125px;
    width: 271px;
  }
  
  .reports .group-27 {
    height: 791px;
  }
  
  .reports .group-28 {
    background-color: #4270e7f2;
    border-radius: 6px;
    height: 791px;
    width: 271px;
  }
  
  .reports .group-29 {
    height: 552px;
    left: 8px;
    position: relative;
    top: 23px;
    width: 260px;
  }
  
  .reports .side-nav-devider {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    position: absolute;
    top: 356px;
    width: 256px;
  }
  
  .reports .tasks {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 106px;
    width: 185px;
  }
  
  .reports .text-medium-13-darkblue {
    left: 34px !important;
    position: absolute !important;
    top: 0 !important;
    width: 151px !important;
  }
  
  .reports .text-medium-instance {
    color: #ffffff !important;
    font-size: 16px !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .reports .icon-instance-node {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 20px !important;
  }
  
  .reports .group-30 {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 430px;
    width: 92px;
  }
  
  .reports .settings {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 58px;
  }
  
  .reports .text-medium-13-darkblue-instance {
    width: unset !important;
  }
  
  .reports .design-component-instance-node {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 58px !important;
  }
  
  .reports .text-wrapper-35 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 17px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 52px;
  }
  
  .reports .group-31 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 157px;
    width: 185px;
  }
  
  .reports .group-32 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 206px;
    width: 94px;
  }
  
  .reports .contacts {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .reports .group-33 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 256px;
    width: 91px;
  }
  
  .reports .chat {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .reports .text-wrapper-36 {
    color: #ffffff;
    font-family: var(--medium-13px-font-family);
    font-size: var(--medium-13px-font-size);
    font-style: var(--medium-13px-font-style);
    font-weight: var(--medium-13px-font-weight);
    left: 51px;
    letter-spacing: var(--medium-13px-letter-spacing);
    line-height: var(--medium-13px-line-height);
    position: absolute;
    top: 382px;
    width: 56px;
  }
  
  .reports .group-34 {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 480px;
    width: 64px;
  }
  
  .reports .text-medium-13-darkblue-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .reports .text-medium-2 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 30px !important;
  }
  
  .reports .dashboard {
    height: 21px;
    left: 14px;
    position: absolute;
    top: 51px;
    width: 129px;
  }
  
  .reports .text-medium-13-accent {
    left: 36px !important;
    position: absolute !important;
    top: 0 !important;
    width: 93px !important;
  }
  
  .reports .group-35 {
    height: 40px;
    left: 0;
    position: absolute;
    top: 303px;
    width: 240px;
  }
  
  .reports .overlap-group-7 {
    height: 40px;
    position: relative;
  }
  
  .reports .rectangle-5 {
    background-color: #ffffff;
    border-radius: 6px;
    height: 40px;
    left: 5px;
    position: absolute;
    top: 0;
    width: 235px;
  }
  
  .reports .group-36 {
    height: 22px;
    left: 13px;
    position: absolute;
    top: 8px;
    width: 99px;
  }
  
  .reports .deals {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .reports .text-medium-3 {
    color: #413ef7 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .reports .file {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 2px !important;
    width: 20px !important;
  }
  
  .reports .rectangle-6 {
    background-color: #413ef7;
    border-radius: 20px 0px 0px 10px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .reports .group-37 {
    height: 22px;
    left: 19px;
    position: absolute;
    top: 530px;
    width: 90px;
  }
  
  .reports .log-out {
    height: 22px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 22px !important;
  }
  
  .reports .text-medium-instance-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 2px;
    width: 56px;
  }
  
  .reports .text-medium-4 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 56px !important;
  }
  