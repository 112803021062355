.text-medium-wrapper {
  height: 20px;
  position: relative;
  width: 174px;
}

.text-medium-wrapper .side-bar-title-2 {
  color: var(--mainaccent-blue);
  font-family: "Poppins", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0.13px;
  line-height: normal;
  position: absolute;
  top: -1px;
  width: 174px;
}
