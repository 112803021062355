.dashboard {
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .dashboard .div-wrapper {
    background-color: #ededed;
    height: 950px;
    width: 1310px;
  }
  
  .dashboard .overlap-5 {
    background-color: #d9d9d9;
    height: 950px;
    position: relative;
  }
  
  .dashboard .group-2 {
    height: 100px;
    left: 21px;
    position: absolute;
    top: 0;
    width: 1268px;
  }
  
  .dashboard .overlap-6 {
    height: 100px;
    position: relative;
  }
  
  .dashboard .group-3 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 245px;
  }
  
  .dashboard .overlap-group-2 {
    background-color: #ffffff;
    height: 100px;
    position: relative;
    width: 243px;
  }
  
  .dashboard .text-wrapper-6 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 100px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 38px;
    width: 44px;
  }
  
  .dashboard .HEADER-wrapper {
    height: 100px;
    left: 243px;
    position: absolute;
    top: 0;
    width: 1025px;
  }
  
  .dashboard .HEADER {
    height: 100px;
    width: 1027px;
  }
  
  .dashboard .overlap-7 {
    height: 100px;
    position: relative;
    width: 1025px;
  }
  
  .dashboard .header {
    background-color: #ffffff;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1025px;
  }
  
  .dashboard .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 16.14px;
    left: 65px;
    position: absolute;
    top: 17px;
  }
  
  .dashboard .headertabs-default {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16.14px;
    padding: 3.03px 0px;
    position: relative;
  }
  
  .dashboard .headertabitem {
    flex: 0 0 auto;
    height: 32.29px;
    position: relative;
  }
  
  .dashboard .search-bar {
    border-radius: 6px;
    height: 61px;
    left: 388px;
    overflow: hidden;
    position: absolute;
    top: 19px;
    width: 595px;
  }
  
  .dashboard .user-name {
    height: 44px;
    left: 416px;
    position: absolute;
    top: 9px;
    width: 177px;
  }
  
  .dashboard .photo {
    height: 44px;
    left: 131px;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .dashboard .overlap-group-3 {
    border-radius: 23.5px;
    height: 47px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 47px;
  }
  
  .dashboard .avatar-man-header {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 40px;
  }
  
  .dashboard .profile-circle {
    border: 1.5px solid;
    border-color: var(--grayscale-divider);
    border-radius: 23.5px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 47px;
  }
  
  .dashboard .name {
    color: var(--grayscale-black);
    font-family: var(--semibold-14-0-2-px-font-family);
    font-size: var(--semibold-14-0-2-px-font-size);
    font-style: var(--semibold-14-0-2-px-font-style);
    font-weight: var(--semibold-14-0-2-px-font-weight);
    left: 30px;
    letter-spacing: var(--semibold-14-0-2-px-letter-spacing);
    line-height: var(--semibold-14-0-2-px-line-height);
    position: absolute;
    text-align: right;
    top: 13px;
    white-space: nowrap;
    width: 87px;
  }
  
  .dashboard .icon-notifications {
    height: 16px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 16px;
  }
  
  .dashboard .overlap-8 {
    height: 18px;
    left: 1px;
    position: relative;
    top: -2px;
  }
  
  .dashboard .vector {
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .dashboard .new {
    background-color: #413ef7;
    border: 1.5px solid;
    border-color: var(--grayscale-bg);
    border-radius: 4.5px;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 9px;
  }
  
  .dashboard .group-4 {
    height: 44px;
    left: 2px;
    position: absolute;
    top: 7px;
    width: 362px;
  }
  
  .dashboard .overlap-9 {
    background-color: #ffffff;
    /* border: 1px solid;
    border-color: #cdcdcd; */
    border-radius: 50px;
    height: 44px;
    position: relative;
    width: 360px;
  }
  
  .dashboard .img {
    height: 20px;
    left: 21px;
    position: absolute;
    top: 11px;
    width: 21px;
  }
  
  .dashboard .text-wrapper-7 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .dashboard .text-wrapper-8 {
    color: #000000;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 69px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 32px;
    width: 275px;
  }
  
  .dashboard .group-wrapper {
    height: 788px;
    left: 21px;
    position: absolute;
    top: 130px;
    width: 1268px;
  }
  
  .dashboard .group-5 {
    height: 788px;
    position: relative;
  }
  
  .dashboard .group-6 {
    height: 788px;
    left: 292px;
    position: absolute;
    top: 0;
    width: 976px;
    /* background-color: #ffffff; */
  }
  
  .dashboard .group-225 {
    left: 3px !important;
    position: absolute !important;
    top: 0 !important;
  }
  
  .dashboard .rectangle {
    background-color: #ffffff;
    border-radius: 5px;
    height: 669px;
    left: 0;
    position: absolute;
    top: 119px;
    width: 976px;
  }
  
  .dashboard .group-7 {
    background-color: #4270e7f2;
    border-radius: 6px;
    height: 788px;
    left: 0;
    position: absolute;
    top: 0;
    width: 271px;
  }
  
  .dashboard .group-8 {
    height: 552px;
    left: 8px;
    position: relative;
    top: 25px;
    width: 260px;
  }
  
  .dashboard .side-nav-devider {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    position: absolute;
    top: 356px;
    width: 256px;
  }
  
  .dashboard .tasks {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 106px;
    width: 185px;
  }
  
  .dashboard .text-medium-13-darkblue {
    left: 34px !important;
    position: absolute !important;
    top: 0 !important;
    width: 151px !important;
    cursor : pointer;
  }
  
  .dashboard .text-medium-instance {
    color: #ffffff !important;
    font-size: 16px !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
    cursor : pointer;
  }
  
  .dashboard .icon-instance-node {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 20px !important;
  }
  
  .dashboard .group-9 {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 430px;
    width: 92px;
  }
  
  .dashboard .settings {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 58px;
  }
  
  .dashboard .text-medium-13-darkblue-instance {
    width: unset !important;
  }
  
  .dashboard .design-component-instance-node {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 58px !important;
  }
  
  .dashboard .group-10 {
    height: 22px;
    left: 19px;
    position: absolute;
    top: 530px;
    width: 90px;
  }
  
  .dashboard .log-out {
    height: 22px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 22px !important;
  }
  
  .dashboard .text-medium-13-darkblue-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 2px;
    width: 56px;
  }
  
  .dashboard .text-medium-2 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 56px !important;
  }
  
  .dashboard .text-wrapper-9 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 17px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 52px;
  }
  
  .dashboard .text-medium-3 {
    left: 51px !important;
    position: absolute !important;
    top: 156px !important;
    width: 151px !important;
  }
  
  .dashboard .users {
    height: 20px !important;
    left: 18px !important;
    position: absolute !important;
    top: 154px !important;
    width: 20px !important;
  }
  
  .dashboard .group-11 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 206px;
    width: 94px;
  }
  
  .dashboard .contacts {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .dashboard .group-12 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 256px;
    width: 91px;
  }
  
  .dashboard .chat {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .dashboard .group-13 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 306px;
    width: 99px;
  }
  
  .dashboard .deals {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .dashboard .text-wrapper-10 {
    color: #ffffff;
    font-family: var(--medium-13px-font-family);
    font-size: var(--medium-13px-font-size);
    font-style: var(--medium-13px-font-style);
    font-weight: var(--medium-13px-font-weight);
    left: 51px;
    letter-spacing: var(--medium-13px-letter-spacing);
    line-height: var(--medium-13px-line-height);
    position: absolute;
    top: 382px;
    width: 56px;
  }
  
  .dashboard .group-14 {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 480px;
    width: 64px;
  }
  
  .dashboard .text-medium-instance-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .dashboard .text-medium-4 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 30px !important;
  }
  
  .dashboard .group-15 {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 48px;
    width: 236px;
  }
  
  .dashboard .overlap-group-4 {
    height: 40px;
    position: relative;
  }
  
  .dashboard .rectangle-2 {
    background-color: #ffffff;
    border-radius: 6px;
    height: 40px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 235px;
  }
  
  .dashboard .div-2 {
    height: 31px;
    left: 11px;
    position: absolute;
    top: 7px;
    width: 149px;
  }
  
  .dashboard .text-medium-13-accent {
    height: 29px !important;
    left: 42px !important;
    position: absolute !important;
    top: 3px !important;
    width: 107px !important;
  }
  
  .dashboard .text-medium-13-accent-instance {
    color: #413ef7 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
    cursor: pointer !important;
  }
  
  .dashboard .rectangle-3 {
    background-color: #413ef7;
    border-radius: 20px 0px 0px 10px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }

  /* dashboard.css (or any other CSS file) */

.sidebar-item {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-item.active {
  background-color: #ffffff;
  border-radius: 6px;
  color: blue;
}

.active-text {
  color: blue;
}

/* Active tab styles */
.active-tab {
  background-color: #ffffff;
  border-radius: 6px;
  height: 40px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 235px;
  color: blue; /* Change this to the desired text color */
}


  