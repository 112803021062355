.ROLE {
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

  }
  
  .ROLE .div {
    background-color: #ededed;
    height: 950px;
    position: relative;
    width: 1310px;
    /* left : 300px; */
  }
  
  .ROLE .group {
    background-color: #4270e7f2;
    border-radius: 6px;
    height: 780px;
    left: 18px;
    position: absolute;
    top: 125px;
    width: 271px;
  }
  
  .ROLE .group-2 {
    height: 552px;
    left: 8px;
    position: relative;
    top: 23px;
    width: 260px;
  }
  
  .ROLE .side-nav-devider {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    position: absolute;
    top: 356px;
    width: 256px;
  }
  
  .ROLE .tasks {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 106px;
    width: 185px;
  }
  
  .ROLE .text-medium-13-darkblue {
    left: 34px !important;
    position: absolute !important;
    top: 0 !important;
    width: 151px !important;
  }
  
  .ROLE .text-medium-instance {
    color: #ffffff !important;
    font-size: 16px !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .ROLE .icon-instance-node {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 20px !important;
  }
  
  .ROLE .group-3 {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 430px;
    width: 92px;
  }
  
  .ROLE .settings {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 58px;
  }
  
  .ROLE .text-medium-13-darkblue-instance {
    width: unset !important;
  }
  
  .ROLE .design-component-instance-node {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 58px !important;
  }
  
  .ROLE .group-4 {
    height: 22px;
    left: 19px;
    position: absolute;
    top: 530px;
    width: 90px;
  }
  
  .ROLE .log-out {
    height: 22px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 22px !important;
  }
  
  .ROLE .text-medium-13-darkblue-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 2px;
    width: 56px;
  }
  
  .ROLE .text-medium-2 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 56px !important;
  }
  
  .ROLE .text-wrapper-2 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 17px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 52px;
  }
  
  .ROLE .group-5 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 206px;
    width: 94px;
  }
  
  .ROLE .contacts {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .ROLE .group-6 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 256px;
    width: 91px;
  }
  
  .ROLE .chat {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .ROLE .group-7 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 306px;
    width: 99px;
  }
  
  .ROLE .deals {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .ROLE .text-wrapper-3 {
    color: #ffffff;
    font-family: var(--medium-13px-font-family);
    font-size: var(--medium-13px-font-size);
    font-style: var(--medium-13px-font-style);
    font-weight: var(--medium-13px-font-weight);
    left: 51px;
    letter-spacing: var(--medium-13px-letter-spacing);
    line-height: var(--medium-13px-line-height);
    position: absolute;
    top: 382px;
    width: 56px;
  }
  
  .ROLE .group-8 {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 480px;
    width: 64px;
  }
  
  .ROLE .text-medium-instance-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .ROLE .text-medium-3 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 30px !important;
  }
  
  .ROLE .dashboard {
    height: 21px;
    left: 14px;
    position: absolute;
    top: 51px;
    width: 129px;
  }
  
  .ROLE .text-medium-13-accent {
    left: 36px !important;
    position: absolute !important;
    top: 0 !important;
    width: 93px !important;
  }
  
  .ROLE .overlap-group-wrapper {
    height: 40px;
    left: 3px;
    position: absolute;
    top: 149px;
    width: 237px;
  }
  
  .ROLE .overlap-group {
    height: 40px;
    position: relative;
  }
  
  .ROLE .rectangle {
    background-color: #ffffff;
    border-radius: 6px;
    height: 40px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 235px;
  }
  
  .ROLE .group-9 {
    height: 20px;
    left: 14px;
    position: absolute;
    top: 9px;
    width: 185px;
  }
  
  .ROLE .text-medium-4 {
    color: #413ef7 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .ROLE .rectangle-2 {
    background-color: #413ef7;
    border-radius: 20px 0px 0px 10px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .ROLE .group-wrapper {
    background-color: #ffffff;
    border-radius: 5px;
    height: 780px;
    left: 317px;
    position: absolute;
    top: 125px;
    width: 976px;
  }
  
  .ROLE .group-10 {
    height: 680px;
    left: 25px;
    position: relative;
    top: 20px;
    width: 936px;
  }
  
  .ROLE .group-11 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 900px;
  }
  
  .ROLE .div-wrapper {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 362px;
  }
  
  .ROLE .overlap-group-2 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #cdcdcd;
    border-radius: 50px;
    height: 44px;
    position: relative;
    width: 360px;
  }
  
  .ROLE .vector {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 11px;
    width: 20px;
  }
  
  .ROLE .text-wrapper-4 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 51px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .ROLE .img {
    height: 52px;
    left: 777px;
    position: absolute;
    top: 0;
    width: 127px;
  }
  
  .ROLE .group-12 {
    height: 596px;
    left: 0;
    position: absolute;
    top: 84px;
    width: 936px;
  }
  
  .ROLE .group-13 {
    height: 596px;
    left: 0;
    position: absolute;
    top: 0;
    width: 900px;
  }
  
  .ROLE .group-14 {
    height: 21px;
    left: 680px;
    position: absolute;
    top: 575px;
    width: 224px;
  }
  
  .ROLE .text-wrapper-5 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 24px;
    letter-spacing: 0;
    line-height: 21px;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 62px;
  }
  
  .ROLE .text-wrapper-6 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 127px;
    letter-spacing: 0;
    line-height: 21px;
    opacity: 0.8;
    position: absolute;
    top: 0;
    width: 93px;
  }
  
  .ROLE .ellipse {
    background-color: #02e40b;
    border-radius: 6.56px/6px;
    height: 12px;
    left: 0;
    opacity: 0.8;
    position: absolute;
    top: 4px;
    width: 13px;
  }
  
  .ROLE .ellipse-2 {
    background-color: #c41425;
    border-radius: 6.56px/6px;
    height: 12px;
    left: 103px;
    opacity: 0.8;
    position: absolute;
    top: 4px;
    width: 13px;
  }
  
  .ROLE .group-15 {
    height: 560px;
    left: 0;
    position: absolute;
    top: 0;
    width: 900px;
  }
  
  .ROLE .group-16 {
    height: 70px;
    left: 0;
    position: absolute;
    top: 90px;
    width: 906px;
  }
  
  .ROLE .overlap-group-3 {
    background-color: #fffafa;
    border-radius: 8px;
    box-shadow: 1px 0px 2px #0000001a, 0px 1px 2px #0000001a;
    height: 70px;
    position: relative;
    width: 900px;
  }
  
  .ROLE .text-wrapper-7 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 107px;
  }
  
  .ROLE .text-wrapper-8 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 276px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 61px;
  }
  
  .ROLE .ellipse-wrapper {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #02e40b80;
    border-radius: 14.22px/13px;
    height: 26px;
    left: 805px;
    position: absolute;
    top: 22px;
    width: 28px;
  }
  
  .ROLE .ellipse-3 {
    background-color: #02e40b;
    border-radius: 10.94px/10px;
    height: 20px;
    left: 2px;
    position: relative;
    top: 2px;
    width: 22px;
  }
  
  .ROLE .text-wrapper-9 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 510px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    width: 166px;
  }
  
  .ROLE .overlap-wrapper {
    height: 70px;
    left: 0;
    position: absolute;
    top: 170px;
    width: 906px;
  }
  
  .ROLE .text-wrapper-10 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 97px;
  }
  
  .ROLE .text-wrapper-11 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 270px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 119px;
  }
  
  .ROLE .group-17 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #c4142580;
    border-radius: 14.22px/13px;
    height: 26px;
    left: 805px;
    position: absolute;
    top: 22px;
    width: 28px;
  }
  
  .ROLE .ellipse-4 {
    background-color: #c41425;
    border-radius: 10.94px/10px;
    height: 20px;
    left: 2px;
    position: relative;
    top: 2px;
    width: 22px;
  }
  
  .ROLE .text-wrapper-12 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 510px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    width: 93px;
  }
  
  .ROLE .group-18 {
    height: 70px;
    left: 0;
    position: absolute;
    top: 250px;
    width: 906px;
  }
  
  .ROLE .text-wrapper-13 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    white-space: nowrap;
    width: 112px;
  }
  
  .ROLE .text-wrapper-14 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 272px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 145px;
  }
  
  .ROLE .group-19 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #c4142580;
    border-radius: 14.22px/13px;
    height: 26px;
    left: 807px;
    position: absolute;
    top: 22px;
    width: 28px;
  }
  
  .ROLE .text-wrapper-15 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 512px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    width: 70px;
  }
  
  .ROLE .group-20 {
    height: 70px;
    left: 0;
    position: absolute;
    top: 330px;
    width: 906px;
  }
  
  .ROLE .text-wrapper-16 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 75px;
  }
  
  .ROLE .text-wrapper-17 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 270px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 195px;
  }
  
  .ROLE .text-wrapper-18 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 510px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    width: 206px;
  }
  
  .ROLE .group-21 {
    height: 70px;
    left: 0;
    position: absolute;
    top: 410px;
    width: 906px;
  }
  
  .ROLE .text-wrapper-19 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 122px;
  }
  
  .ROLE .text-wrapper-20 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 270px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 157px;
  }
  
  .ROLE .text-wrapper-21 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 510px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    width: 87px;
  }
  
  .ROLE .group-22 {
    height: 70px;
    left: 0;
    position: absolute;
    top: 490px;
    width: 906px;
  }
  
  .ROLE .text-wrapper-22 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 26px;
    width: 103px;
  }
  
  .ROLE .text-wrapper-23 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 510px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 25px;
    width: 102px;
  }
  
  .ROLE .group-23 {
    height: 80px;
    left: 0;
    position: absolute;
    top: 0;
    width: 908px;
  }
  
  .ROLE .overlap {
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 2px 0px 4px #0000001a, 0px 1px 4px #0000001a;
    height: 80px;
    position: relative;
    width: 900px;
  }
  
  .ROLE .text-wrapper-24 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 38px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 30px;
    width: 86px;
  }
  
  .ROLE .text-wrapper-25 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 276px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 30px;
    width: 61px;
  }
  
  .ROLE .text-wrapper-26 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 783px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 30px;
    width: 58px;
  }
  
  .ROLE .text-wrapper-27 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 600;
    left: 507px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    top: 29px;
    width: 118px;
  }
  
  .ROLE .rectangle-wrapper {
    background-color: #d9d9d9;
    border-radius: 20px;
    height: 550px;
    left: 921px;
    position: absolute;
    top: 3px;
    width: 15px;
  }
  
  .ROLE .rectangle-3 {
    background-color: #3c3b3b80;
    border-radius: 20px;
    height: 79px;
    left: 2px;
    position: relative;
    top: 50px;
    width: 11px;
  }
  
  .ROLE .group-24 {
    height: 100px;
    left: 18px;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .ROLE .overlap-2 {
    height: 100px;
    position: relative;
  }
  
  .ROLE .group-25 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 280px;
  }
  
  .ROLE .overlap-group-4 {
    background-color: #ffffff;
    height: 100px;
    position: relative;
    width: 278px;
  }
  
  .ROLE .text-wrapper-28 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 114px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 38px;
    width: 50px;
  }
  
  .ROLE .HEADER-wrapper {
    height: 100px;
    left: 278px;
    position: absolute;
    top: 0;
    width: 997px;
  }
  
  .ROLE .HEADER {
    height: 100px;
    width: 999px;
  }
  
  .ROLE .overlap-3 {
    height: 100px;
    position: relative;
    width: 997px;
  }
  
  .ROLE .header {
    background-color: #ffffff;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 997px;
  }
  
  .ROLE .search-bar {
    border-radius: 6px;
    height: 61px;
    left: 378px;
    overflow: hidden;
    position: relative;
    top: 19px;
    width: 579px;
  }
  
  .ROLE .user-name {
    height: 44px;
    left: 400px;
    position: absolute;
    top: 9px;
    width: 177px;
  }
  
  .ROLE .photo {
    height: 44px;
    left: 131px;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .ROLE .overlap-group-5 {
    border-radius: 23.5px;
    height: 47px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 47px;
  }
  
  .ROLE .avatar-man-header {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 40px;
  }
  
  .ROLE .profile-circle {
    border: 1.5px solid;
    border-color: var(--grayscale-divider);
    border-radius: 23.5px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 47px;
  }
  
  .ROLE .name {
    color: var(--grayscale-black);
    font-family: var(--semibold-14-0-2-px-font-family);
    font-size: var(--semibold-14-0-2-px-font-size);
    font-style: var(--semibold-14-0-2-px-font-style);
    font-weight: var(--semibold-14-0-2-px-font-weight);
    left: 30px;
    letter-spacing: var(--semibold-14-0-2-px-letter-spacing);
    line-height: var(--semibold-14-0-2-px-line-height);
    position: absolute;
    text-align: right;
    top: 13px;
    white-space: nowrap;
    width: 87px;
  }
  
  .ROLE .icon-notifications {
    height: 16px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 16px;
  }
  
  .ROLE .overlap-4 {
    height: 18px;
    left: 1px;
    position: relative;
    top: -2px;
  }
  
  .ROLE .vector-2 {
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .ROLE .new {
    background-color: #413ef7;
    border: 1.5px solid;
    border-color: var(--grayscale-bg);
    border-radius: 4.5px;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 9px;
  }
  
  .ROLE .group-26 {
    height: 44px;
    left: 2px;
    position: absolute;
    top: 7px;
    width: 362px;
  }
  
  .ROLE .vector-3 {
    height: 20px;
    left: 20px;
    position: absolute;
    top: 11px;
    width: 20px;
  }
  
  .ROLE .text-wrapper-29 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .ROLE .text-wrapper-30 {
    color: #413ef7;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 41px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 35px;
  }

  
