.user {
    background-color: #ededed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .user .group-wrapper {
    background-color: #ededed;
    height: 950px;
    width: 1310px;
  }
  
  .user .group {
    height: 905px;
    left: 14px;
    position: relative;
    width: 1276px;
  }
  
  /* .user .div-wrapper {
    background-color: #4270e7f2;
    border-radius: 6px;
    height: 780px;
    left: 0;
    position: absolute;
    top: 125px;
    width: 271px;
  }
  
  .user .div {
    height: 552px;
    left: 8px;
    position: relative;
    top: 23px;
    width: 260px;
  }
  
  .user .side-nav-devider {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    position: absolute;
    top: 356px;
    width: 256px;
  }
  
  .user .overlap-group {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 98px;
    width: 236px;
  }
  
  .user .tasks-wrapper {
    background-color: #ffffff;
    border-radius: 6px;
    height: 40px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 235px;
  }
  
  .user .tasks {
    height: 20px;
    left: 12px;
    position: relative;
    top: 9px;
    width: 185px;
  }
  
  .user .text-medium-13-darkblue {
    left: 34px !important;
    position: absolute !important;
    top: 0 !important;
    width: 151px !important;
  }
  
  .user .text-medium-instance {
    color: #413ef7 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .user .icon-instance-node {
    height: 20px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 20px !important;
  }
  
  .user .rectangle {
    background-color: #413ef7;
    border-radius: 20px 0px 0px 10px;
    height: 40px;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
  
  .user .group-2 {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 430px;
    width: 92px;
  }
  
  .user .settings {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 58px;
  }
  
  .user .text-medium-13-darkblue-instance {
    width: unset !important;
  }
  
  .user .design-component-instance-node {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 58px !important;
  }
  
  .user .group-3 {
    height: 22px;
    left: 19px;
    position: absolute;
    top: 530px;
    width: 90px;
  }
  
  .user .log-out {
    height: 22px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 22px !important;
  }
  
  .user .text-medium-13-darkblue-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 2px;
    width: 56px;
  }
  
  .user .text-medium-2 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 56px !important;
  }
  
  .user .text-wrapper-2 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 17px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
    width: 52px;
  }
  
  .user .group-4 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 156px;
    width: 185px;
  }
  
  .user .text-medium-3 {
    color: #ffffff !important;
    font-size: 16px !important;
    letter-spacing: 0.16px !important;
    width: unset !important;
  }
  
  .user .group-5 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 206px;
    width: 94px;
  }
  
  .user .contacts {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 60px;
  }
  
  .user .group-6 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 256px;
    width: 91px;
  }
  
  .user .chat {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 57px;
  }
  
  .user .group-7 {
    height: 20px;
    left: 17px;
    position: absolute;
    top: 306px;
    width: 99px;
  }
  
  .user .deals {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 65px;
  }
  
  .user .text-wrapper-3 {
    color: #ffffff;
    font-family: var(--medium-13px-font-family);
    font-size: var(--medium-13px-font-size);
    font-style: var(--medium-13px-font-style);
    font-weight: var(--medium-13px-font-weight);
    left: 51px;
    letter-spacing: var(--medium-13px-letter-spacing);
    line-height: var(--medium-13px-line-height);
    position: absolute;
    top: 382px;
    width: 56px;
  }
  
  .user .group-8 {
    height: 20px;
    left: 19px;
    position: absolute;
    top: 480px;
    width: 64px;
  }
  
  .user .text-medium-instance-wrapper {
    height: 20px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 30px;
  }
  
  .user .text-medium-4 {
    color: #ffffff !important;
    font-family: var(--medium-13px-font-family) !important;
    font-size: var(--medium-13px-font-size) !important;
    font-style: var(--medium-13px-font-style) !important;
    font-weight: var(--medium-13px-font-weight) !important;
    letter-spacing: var(--medium-13px-letter-spacing) !important;
    line-height: var(--medium-13px-line-height) !important;
    width: 30px !important;
  }
  
  .user .dashboard {
    height: 21px;
    left: 14px;
    position: absolute;
    top: 51px;
    width: 129px;
  }
  
  .user .text-medium-13-accent {
    left: 36px !important;
    position: absolute !important;
    top: 0 !important;
    width: 93px !important;
  }
   */
  .user .group-9 {
    background-color: #ffffff;
    border-radius: 5px;
    height: 780px;
    /* left: 300px; */
    position: absolute;
    top: 5px;
    width: 976px;
  }
  
  .user .group-10 {
    height: 726px;
    left: 15px;
    position: relative;
    top: 25px;
    width: 946px;
  }
  
  .user .group-11 {
    height: 720px;
    left: 0;
    position: absolute;
    top: 0;
    width: 911px;
  }
  
  .user .group-12 {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 911px;
  }
  
  .user .overlap-group-wrapper {
    height: 44px;
    left: 0;
    position: absolute;
    top: 0;
    width: 362px;
  }
  
  .user .overlap-group-2 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #cdcdcd;
    border-radius: 50px;
    height: 44px;
    position: relative;
    width: 360px;
  }
  
  .user .vector {
    height: 20px;
    left: 18px;
    position: absolute;
    top: 11px;
    width: 20px;
  }
  
  .user .text-wrapper-4 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 50px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .user .img {
    height: 52px;
    left: 788px;
    position: absolute;
    top: 0;
    width: 127px;
  }
  
  .user .group-13 {
    height: 651px;
    left: 0;
    position: absolute;
    top: 69px;
    width: 911px;
  }
  
  .user .group-14 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 911px;
  }
  
  .user .group-15 {
    height: 206px;
    left: 307px;
    position: absolute;
    top: -3px;
    width: 296px;
  }
  
  .user .group-16 {
    box-shadow: 0px 0px 3px #00000040;
    height: 200px;
    left: 0;
    position: absolute;
    top: 0;
    width: 290px;
  }
  
  .user .group-17 {
    background-color: #ffffff;
    border: 0.4px solid;
    border-color: #999999;
    border-radius: 15px;
    height: 200px;
  }
  
  .user .group-18 {
    height: 129px;
    left: 19px;
    position: relative;
    top: 35px;
    width: 231px;
  }
  
  .user .text-wrapper-5 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 68px;
    white-space: nowrap;
    width: 132px;
  }
  
  .user .text-wrapper-6 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 105px;
    white-space: nowrap;
    width: 125px;
  }
  
  .user .text-wrapper-7 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 105px;
    white-space: nowrap;
    width: 71px;
  }
  
  .user .text-wrapper-8 {
    color: #756969;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 81px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 38px;
    white-space: nowrap;
    width: 140px;
  }
  
  .user .ellipse {
    height: 50px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 52px;
  }
  
  .user .text-wrapper-9 {
    color: #413ef7;
    font-family: "Poppins", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 80px;
    letter-spacing: 0;
    line-height: 21px;
    position: absolute;
    text-align: center;
    top: 8px;
    white-space: nowrap;
    width: 105px;
  }
  
  .user .group-19 {
    height: 206px;
    left: 618px;
    position: absolute;
    top: -3px;
    width: 296px;
  }
  
  .user .group-20 {
    height: 200px;
    left: 0;
    position: absolute;
    top: 225px;
    width: 911px;
  }
  
  .user .group-21 {
    height: 206px;
    left: -3px;
    position: absolute;
    top: -3px;
    width: 296px;
  }
  
  .user .group-22 {
    height: 201px;
    left: 0;
    position: absolute;
    top: 450px;
    width: 911px;
  }
  
  .user .group-23 {
    height: 206px;
    left: 307px;
    position: absolute;
    top: -2px;
    width: 296px;
  }
  
  .user .overlap-wrapper {
    height: 657px;
    left: 931px;
    position: absolute;
    top: 69px;
    width: 15px;
  }
  
  .user .overlap {
    border-radius: 20px;
    height: 657px;
    position: relative;
  }
  
  .user .rectangle-wrapper {
    background-color: #d9d9d9;
    border-radius: 20px;
    height: 657px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
  }
  
  .user .rectangle-2 {
    background-color: #3c3b3b80;
    border-radius: 20px;
    height: 80px;
    left: 2px;
    position: relative;
    top: 71px;
    width: 11px;
  }
  
  .user .vector-2 {
    height: 6px;
    left: 3px;
    position: absolute;
    top: 641px;
    width: 10px;
  }
  
  .user .vector-3 {
    height: 6px;
    left: 3px;
    position: absolute;
    top: 9px;
    width: 10px;
  }
  
  .user .group-24 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1275px;
  }
  
  .user .overlap-2 {
    height: 100px;
    position: relative;
  }
  
  .user .group-25 {
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 280px;
  }
  
  .user .overlap-group-3 {
    background-color: #ffffff;
    height: 100px;
    position: relative;
    width: 278px;
  }
  
  .user .text-wrapper-10 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 114px;
    letter-spacing: 0.16px;
    line-height: normal;
    position: absolute;
    top: 38px;
    width: 50px;
  }
  
  .user .HEADER-wrapper {
    height: 100px;
    left: 278px;
    position: absolute;
    top: 0;
    width: 997px;
  }
  
  .user .HEADER {
    height: 100px;
    width: 999px;
  }
  
  .user .overlap-3 {
    height: 100px;
    position: relative;
    width: 997px;
  }
  
  .user .header {
    background-color: #ffffff;
    height: 100px;
    left: 0;
    position: absolute;
    top: 0;
    width: 997px;
  }
  
  .user .search-bar {
    border-radius: 6px;
    height: 61px;
    left: 378px;
    overflow: hidden;
    position: relative;
    top: 19px;
    width: 579px;
  }
  
  .user .user-name {
    height: 44px;
    left: 400px;
    position: absolute;
    top: 9px;
    width: 177px;
  }
  
  .user .photo {
    height: 44px;
    left: 131px;
    position: absolute;
    top: 0;
    width: 44px;
  }
  
  .user .overlap-group-4 {
    border-radius: 23.5px;
    height: 47px;
    left: -2px;
    position: relative;
    top: -2px;
    width: 47px;
  }
  
  .user .avatar-man-header {
    height: 40px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 40px;
  }
  
  .user .profile-circle {
    border: 1.5px solid;
    border-color: var(--grayscale-divider);
    border-radius: 23.5px;
    height: 47px;
    left: 0;
    position: absolute;
    top: 0;
    width: 47px;
  }
  
  .user .name {
    color: var(--grayscale-black);
    font-family: var(--semibold-14-0-2-px-font-family);
    font-size: var(--semibold-14-0-2-px-font-size);
    font-style: var(--semibold-14-0-2-px-font-style);
    font-weight: var(--semibold-14-0-2-px-font-weight);
    left: 30px;
    letter-spacing: var(--semibold-14-0-2-px-letter-spacing);
    line-height: var(--semibold-14-0-2-px-line-height);
    position: absolute;
    text-align: right;
    top: 13px;
    white-space: nowrap;
    width: 87px;
  }
  
  .user .icon-notifications {
    height: 16px;
    left: 0;
    position: absolute;
    top: 15px;
    width: 16px;
  }
  
  .user .overlap-4 {
    height: 18px;
    left: 1px;
    position: relative;
    top: -2px;
  }
  
  .user .vector-4 {
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 14px;
  }
  
  .user .new {
    background-color: #413ef7;
    border: 1.5px solid;
    border-color: var(--grayscale-bg);
    border-radius: 4.5px;
    height: 9px;
    left: 8px;
    position: absolute;
    top: 0;
    width: 9px;
  }
  
  .user .group-26 {
    height: 44px;
    left: 2px;
    position: absolute;
    top: 7px;
    width: 362px;
  }
  
  .user .vector-5 {
    height: 20px;
    left: 20px;
    position: absolute;
    top: 11px;
    width: 20px;
  }
  
  .user .text-wrapper-11 {
    color: #999999;
    font-family: "Inter", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 52px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 12px;
  }
  
  .user .text-wrapper-12 {
    color: #413ef7;
    font-family: "Mulish", Helvetica;
    font-size: 24px;
    font-weight: 700;
    left: 41px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 35px;
  }

  
  